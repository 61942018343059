import { TBaseVariableMappingAbstractType } from './types'

export const formatBytes = (size: number) => {
  if (size === 0) return '0 Bytes'

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const exponent = Math.floor(Math.log2(size) / 10)
  const normalizedSize = size / (1 << (10 * exponent))

  return `${normalizedSize.toFixed(2)} ${units[exponent]}`
}

export const getFileType = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toUpperCase()
  return extension || 'Unknown'
}

export const getAbstractTypeFileType = (abstractType: TBaseVariableMappingAbstractType) => {
  // abstractType is in the format {data format / fileType}_{sourceType} eg csv_file, json_file, jsonl_file
  const fileType = abstractType.split('_')[0]
  return fileType
}
