import { JsonSchema, z } from '@invisible/zod'
import { merge } from 'lodash'

import {
  schema as BaseVariableMappingSchema,
  TBaseVariableMappingAbstractType,
} from './BaseVariableMapping'

const schema = z
  .object({
    baseVariableMappings: z.array(BaseVariableMappingSchema).default([]).optional(),
    payloadSchema: z.record(JsonSchema),
    // Text description that shows up at the top for the form for the trigger
    description: z.string().optional(),
  })
  .strict()

type TSchema = z.infer<typeof schema>

const createArgsSchema = schema
type TCreateArgs = z.infer<typeof createArgsSchema>
/**
 * Creates TriggerStepMeta
 */
const create = (data: TCreateArgs): TSchema => schema.parse(createArgsSchema.parse(data))

const updateArgsSchema = z.object({
  baseVariableMappings: BaseVariableMappingSchema.optional(),
  payloadSchema: JsonSchema.optional(),
  description: z.string().optional(),
})
type TUpdateArgs = z.infer<typeof updateArgsSchema>
/**
 * Updates Trigger step meta in place (mutates)
 *
 * Note that Zod will strip out unrecognized keys on parse.
 * See: https://github.com/colinhacks/zod#unrecognized-keys
 */
const update = ({
  triggerStepMeta,
  data,
}: {
  triggerStepMeta: TSchema
  data: TUpdateArgs
}): void => {
  const updateArgs = updateArgsSchema.parse(data)
  merge(triggerStepMeta, updateArgs)
}

const RAW_PAYLOAD_KEY = 'RAW_PAYLOAD'

export { create, createArgsSchema, RAW_PAYLOAD_KEY, schema, update, updateArgsSchema }

export type { TBaseVariableMappingAbstractType, TCreateArgs, TSchema, TUpdateArgs }
