import { CodeArea } from './CodeArea'
import { EnvironmentSelector } from './EnvironmentSelector'
import { useBaseRunVariablesStore } from './stores/baseRunVariablesStore'
import { Environment, TCodeWAC2 } from './types'

type CodeTesterProps = {
  readOnly: boolean
  stepRunId: string
  environments: Environment[]
  config: TCodeWAC2
  updateBRV: (id: undefined | string, value: undefined | string) => Promise<void>
}

const getPackages = (
  environments: Environment[],
  environment: string | undefined
): { name: string; version: string }[] => {
  if (!environment) return []
  const matching = environments.find((item) => item.environment_id === environment)
  if (matching === undefined) return []
  return matching.params.packages
}

export const CodeTester = ({
  readOnly,
  stepRunId,
  environments,
  config,
  updateBRV,
}: CodeTesterProps) => {
  const environment = useBaseRunVariablesStore((state) => state.environment)
  const packages = getPackages(environments, environment)

  return (
    <div>
      <h4>In app code output check</h4>
      <p>Fill out all the fields below, paste your code then hit the execute code button</p>
      <EnvironmentSelector environments={environments} config={config} updateBRV={updateBRV} />
      <ul>
        {packages.map(({ name, version }) => (
          <li key={name}>
            {name} {version}
          </li>
        ))}
      </ul>
      <CodeArea stepRunId={stepRunId} readOnly={readOnly} config={config} updateBRV={updateBRV} />
    </div>
  )
}
