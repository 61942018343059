import { toGlobalId, useCompanyFilesQuery } from '@invisible/concorde/gql-client'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { GridColDef } from '@mui/x-data-grid-pro'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { format, parseISO } from 'date-fns'
import { useMemo, useState } from 'react'

import { formatBytes, getFileType } from '../helpers'
import GridToolbar from './GridToolBar'

const DATAGRID_STYLES = {
  border: 'none',
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
    padding: '0 8px',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-columnHeaders': {
    bgcolor: 'background.default',
    borderBottom: '1px solid',
    borderColor: 'divider',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: ' #604CA533',
    '&:hover': {
      backgroundColor: '#604CA533',
    },
  },
}

interface DatasetTableProps {
  onCancel: () => void
  fileType?: string | null
  onConfirm: (arg: { id: string; name: string }) => void
  companyId: string | null
}

interface TRow {
  id: string
  name: string
  type: string
  size: number
  uploadedOn: string
  uploadedBy: {
    id: string
    name: string
  }
}

const columns: GridColDef<TRow>[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'type', headerName: 'Type', width: 100 },
  {
    field: 'size',
    headerName: 'Size',
    width: 150,
    valueFormatter: (value) => formatBytes(value),
  },
  {
    field: 'uploadedOn',
    headerName: 'Uploaded on',
    width: 200,
    flex: 1,
    valueGetter: (value) => value,
    valueFormatter: (value: string) => {
      if (!value) return ''
      const date = parseISO(value)
      return format(date, "d MMM yyyy 'at' h:mm a")
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseISO(v1)
      const date2 = parseISO(v2)
      return date1.getTime() - date2.getTime()
    },
  },
  {
    field: 'uploadedBy',
    headerName: 'Uploaded by',
    width: 200,
    renderCell: ({ row }) => (
      <Stack direction='row' alignItems='center' height='100%'>
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: 'black',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 1,
          }}>
          {row.uploadedBy?.name?.[0] ?? ''}
        </Box>
        <Typography>{row.uploadedBy.name}</Typography>
      </Stack>
    ),
  },
]

const DatasetTable = ({ onCancel, fileType, onConfirm, companyId }: DatasetTableProps) => {
  const [selectedRow, setSelectedRow] = useState<{ id: string; name: string } | null>(null)
  const { data } = useCompanyFilesQuery(
    {
      companyId: toGlobalId('CompanyType', companyId),
      filters: {
        originalFilename: { endsWith: fileType },
      },
    },
    { enabled: !!companyId }
  )

  const rows: TRow[] = useMemo(
    () =>
      (data?.companyFiles.edges ?? []).map((row) => ({
        id: row.node.id,
        name: row.node.originalFilename,
        type: getFileType(row.node.originalFilename),
        size: row.node.fileSize,
        uploadedOn: row.node.uploadedAt,
        uploadedBy: { id: row.node.uploadedBy.id, name: row.node.uploadedBy.name ?? '-' },
      })),
    [data]
  )

  const handleRowClick = (params: GridRowParams) => {
    setSelectedRow(
      params.id === selectedRow?.id ? null : { id: params.id as string, name: params.row.name }
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '600px',
        paddingTop: 1,
      }}>
      <Box sx={{ height: '100%' }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          disableColumnFilter={false}
          disableColumnSelector={false}
          disableDensitySelector={false}
          onRowClick={handleRowClick}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              primaryText: `${rows.length} datasets`,
            },
          }}
          slots={{
            toolbar: () => <GridToolbar primaryText={`${rows.length} datasets`} />,
            noRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}>
                <Typography>No datasets found</Typography>
              </Box>
            ),
          }}
          disableColumnPinning
          checkboxSelection={false}
          rowSelectionModel={selectedRow ? [selectedRow.id] : []}
          disableColumnMenu
          initialState={{
            sorting: {
              sortModel: [{ field: 'uploadedOn', sort: 'desc' }],
            },
          }}
          hideFooter
          sx={DATAGRID_STYLES}
        />
      </Box>
      <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 1 }}>
        <Button onClick={onCancel} variant='outlined' sx={{ fontWeight: 'normal' }}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!selectedRow}
          onClick={() => {
            if (selectedRow) onConfirm(selectedRow)
          }}
          sx={{
            fontWeight: 'normal',
          }}>
          Done
        </Button>
      </Box>
    </Box>
  )
}

export default DatasetTable
