import CloseIcon from '@mui/icons-material/Close'
import TagIcon from '@mui/icons-material/Tag'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React from 'react'

import DatasetTable from '../datasets/DatasetTable'

interface DatasetDialogProps {
  isOpen: boolean
  onClose: () => void
  activeDataSetField: string | null
  fileType?: string | null
  onConfirmDataset: (label: string, id: string, name: string) => void
  companyId: string | null
}

const styles = {
  dialogPaper: {
    width: '70%',
    height: '800px',
    maxWidth: 'none',
    overflow: 'hidden',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    color: 'white',
    height: '13px',
    fontWeight: 'normal',
  },
}

const DatasetDialog = ({
  isOpen,
  onClose,
  activeDataSetField,
  fileType,
  onConfirmDataset,
  companyId,
}: DatasetDialogProps) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth='md'
    fullWidth
    PaperProps={{
      sx: styles.dialogPaper,
    }}>
    <DialogTitle sx={styles.dialogTitle}>
      <TagIcon sx={{ marginRight: '8px' }} />
      <Typography fontWeight='bold' fontSize={18} sx={{ flexGrow: 1 }}>
        Select file from datasets for {activeDataSetField ?? 'field'}
      </Typography>
      <IconButton onClick={onClose}>
        <CloseIcon sx={{ color: 'white' }} />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DatasetTable
        onCancel={onClose}
        fileType={fileType}
        onConfirm={({ id, name }) => {
          if (!activeDataSetField) return
          onConfirmDataset(activeDataSetField, id, name)
        }}
        companyId={companyId}
      />
    </DialogContent>
  </Dialog>
)

export default DatasetDialog
