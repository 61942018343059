import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro'

const GridToolbar = ({ primaryText }: { primaryText: string }) => (
  <GridToolbarContainer
    sx={{
      backgroundColor: '#0000000A',
      p: 2,
      justifyContent: 'space-between',
      borderRadius: '12px 12px 0 0',
    }}>
    <Stack direction='row' spacing={3} alignItems={'center'}>
      <Typography
        sx={{
          fontWeight: 'normal',
        }}>
        {primaryText}
      </Typography>
    </Stack>
    <Stack direction='row' spacing={3} alignItems='center'>
      <GridToolbarQuickFilter
        fullWidth
        size='small'
        placeholder='Find a dataset...'
        sx={{
          width: '200px',
          '& .MuiInputBase-root': {
            height: '30px',
          },
        }}
        InputProps={{
          startAdornment: <SearchIcon sx={{ color: 'action.active' }} />,
        }}
      />
    </Stack>
  </GridToolbarContainer>
)

export default GridToolbar
