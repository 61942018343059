import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { useBaseRunVariablesStore } from './stores/baseRunVariablesStore'
import { Environment, TCodeWAC2 } from './types'
import { convertOptions, getEnvironments, getLanguages, getVersions } from './utils'

type FormSelectProps = {
  updateBRV: (value: undefined | string) => Promise<void>
  options: string[] | Record<string, string>
  name: 'language' | 'version' | 'environment'
}

type EnvironmentSelectorProps = {
  environments: Environment[]
  config: TCodeWAC2
  updateBRV: (id: undefined | string, value: undefined | string) => Promise<void>
}

const FormSelect = ({ updateBRV, options, name }: FormSelectProps) => {
  const value = useBaseRunVariablesStore((state) => state[name])
  const updateState = useBaseRunVariablesStore((state) => state.updateState)
  const finalOptions = convertOptions(options)

  const handleChange = (e: SelectChangeEvent<string | undefined>) => {
    updateState({ [name]: e.target.value })
    updateBRV(e.target.value)
  }
  return (
    <FormControl variant='outlined' className='w-56'>
      <InputLabel id={`${name}-label`} className='capitalize'>
        {name}
      </InputLabel>
      <Select
        label={name}
        labelId={`${name}-label`}
        size='small'
        onChange={handleChange}
        value={value ?? ''}>
        {Object.entries(finalOptions).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const EnvironmentSelector = ({
  environments,
  config: { languageBaseRunVariableId, versionBaseRunVariableId, environmentBaseRunVariableId },
  updateBRV,
}: EnvironmentSelectorProps) => {
  const language = useBaseRunVariablesStore((state) => state.language)
  const version = useBaseRunVariablesStore((state) => state.version)

  const languages = getLanguages(environments)
  const versions = getVersions(environments, language)
  const environmentsList = getEnvironments(environments, language, version)

  return (
    <div className='flex'>
      <FormSelect
        updateBRV={(v) => updateBRV(languageBaseRunVariableId, v)}
        options={languages}
        name='language'
      />
      <FormSelect
        updateBRV={(v) => updateBRV(versionBaseRunVariableId, v)}
        options={versions}
        name='version'
      />
      <FormSelect
        updateBRV={(v) => updateBRV(environmentBaseRunVariableId, v)}
        options={environmentsList}
        name='environment'
      />
    </div>
  )
}
