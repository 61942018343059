import Checkbox from '@mui/material/Checkbox'
import axios, { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../../config/env'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { CodeTester } from './CodeTester'
import { LocalTester } from './LocalTester'
import { useBaseRunVariablesStore } from './stores/baseRunVariablesStore'
import { CodeWACProps, Environment, EnvironmentsResponse, TCodeWAC2 } from './types'

const CodeWAC2 = (props: CodeWACProps) => {
  const { readOnly, baseRun, stepRun, code2: config } = props
  const [environments, setEnvironments] = useState<Environment[]>([])
  const [runnable, setRunnable] = useState(true)
  const { mutateAsync: updateBaseRunVariables } = useBaseRunVariablesWizardUpdate()

  const updateBRVState = useBaseRunVariablesStore((state) => state.updateState)

  // Populate values into zustand on the first render
  useEffect(() => {
    const values = Object.fromEntries(
      baseRun.baseRunVariables.map((variable) => [variable.baseVariable.id, variable.value])
    )
    const finalObject = {
      code: values[config?.codeBaseRunVariableId ?? ''] as string | undefined,
      language: values[config?.languageBaseRunVariableId ?? ''] as string | undefined,
      version: values[config?.versionBaseRunVariableId ?? ''] as string | undefined,
      environment: values[config?.environmentBaseRunVariableId ?? ''] as string | undefined,
      image: values[config?.imageBaseRunVariableId ?? ''] as string | undefined,
      reason: values[config?.reasonBaseRunVariableId ?? ''] as string | undefined,
    }
    updateBRVState(finalObject)
  }, [])

  useEffect(() => {
    axios
      .get(`${NEXT_PUBLIC_CONCORDE_URL}/api/cem/list-environments?step-run-id=${stepRun.id}`, {
        withCredentials: true,
      })
      .then(({ data, status }: AxiosResponse<EnvironmentsResponse>) => {
        if (status === 200 && data.status === 'success') {
          setEnvironments(data.environments)
        }
      })
  }, [])

  const updateBRV = async (id: undefined | string, value: undefined | string) => {
    // TODO: we should probbably throw some sort of error on undefined values
    if (typeof value === 'string' && typeof id === 'string') {
      const data = {
        baseRunId: stepRun.baseRunId,
        baseVariableId: id as string,
        value: value,
      }
      await updateBaseRunVariables({ stepRunId: stepRun.id, data: [data] })
    }
  }

  const getComponent = (config: undefined | TCodeWAC2, runnable: boolean) => {
    if (!config) {
      return <p>Unexpected error occurred</p>
    }
    if (runnable) {
      return (
        <CodeTester
          readOnly={readOnly}
          stepRunId={stepRun.id}
          environments={environments}
          config={config}
          updateBRV={updateBRV}
        />
      )
    }
    return <LocalTester updateBRV={updateBRV} config={config} />
  }

  return (
    <div className='!rounded border-2 border-solid border-indigo-200'>
      <div className='pl-4 pb-2'>
        <h3>Code Output Check</h3>
        <div className='flex'>
          <Checkbox
            checked={!runnable}
            size='small'
            className='!pl-0'
            onClick={() => setRunnable(!runnable)}
          />
          <p>In app code output check not possible</p>
        </div>
        {getComponent(config, runnable)}
      </div>
    </div>
  )
}

export { CodeWAC2 }
