import { Button } from '@invisible/ui/button'
import { TextArea } from '@invisible/ui/form'
import { ChangeEvent, useState } from 'react'

import { ScreenshotUpload } from './ScreenshotUpload'
import { useBaseRunVariablesStore } from './stores/baseRunVariablesStore'
import { TCodeWAC2 } from './types'

type LocalTesterProps = {
  config: TCodeWAC2
  updateBRV: (id: undefined | string, value: undefined | string) => Promise<void>
}

export type UploadedFile = {
  fileUuid: string
  fileName: string
  url: string
  size: number
  type: string
  filePath: string
}

export const LocalTester = ({
  config: { reasonBaseRunVariableId, imageBaseRunVariableId },
  updateBRV,
}: LocalTesterProps) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const reason = useBaseRunVariablesStore((state) => state.reason)
  const updateState = useBaseRunVariablesStore((state) => state.updateState)

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (isSuccess) {
      setIsSuccess(false)
    }
    updateState({ reason: e.currentTarget.value })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await updateBRV(reasonBaseRunVariableId, reason)
    setLoading(false)
    setIsSuccess(true)
  }

  return (
    <div>
      <h4>Local code output check</h4>
      <p>
        Please upload a screenshot of the code executing on your local environment and tell us why
        the in app check was not possible.
      </p>
      <ScreenshotUpload updateBRV={updateBRV} variable={imageBaseRunVariableId} />

      <div className='my-8'>
        <div className='font-medium'>Reason</div>
        <TextArea
          value={reason || ''}
          rows={10}
          onChange={handleTextAreaChange}
          style={{ resize: 'none' }}
          className='outline-theme-main border-1 max-h-20 max-w-[480px] border-solid '
        />
      </div>
      <Button size='md' onClick={handleSubmit} disabled={loading || isSuccess}>
        Submit
      </Button>
    </div>
  )
}
