import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { startCase } from 'lodash/fp'

interface InputProps {
  label: string
  value: string
  onChange: (newValue: string) => void
  options: string[]
}

export const EnumField = ({ label, value, onChange, options }: InputProps) => (
  <FormControl fullWidth size='small'>
    <InputLabel>{startCase(label)}</InputLabel>
    <Select
      label={startCase(label)}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      size='small'
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '150px',
          },
        },
      }}>
      {options.length > 0 ? (
        options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))
      ) : (
        <MenuItem value=''>No options available</MenuItem>
      )}
    </Select>
  </FormControl>
)
