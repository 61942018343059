import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import { SyntheticEvent, useState } from 'react'

interface StringArrayFieldProps {
  label: string
  value: string[]
  onChange: (newValue: string[]) => void
}

const styles = {
  autocomplete: {
    '& .MuiInputBase-root': {
      minHeight: '40px',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '4px',
      padding: '4px',
    },
    '& .MuiInputBase-input': {
      padding: '0 !important',
      height: '24px',
    },
    '& .MuiAutocomplete-endAdornment': {
      position: 'absolute',
      right: '9px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '& .MuiAutocomplete-clearIndicator': {
      padding: '2px',
    },
    '& .MuiAutocomplete-tag': {
      margin: 0,
    },
    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 12px) scale(1)',
    },
  },
}

export const StringArrayField = ({ label, value, onChange }: StringArrayFieldProps) => {
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: string[]) => {
    onChange(newValue)
  }

  const handleInputChange = (_: SyntheticEvent<Element, Event>, newInputValue: string) => {
    const options = newInputValue.split(',')

    if (options.length > 1) {
      const newOptions = options.map((x) => x.trim()).filter((x) => x && !value.includes(x))
      const allOptions = [...value, ...newOptions]
      onChange(allOptions)
      setInputValue('')
    } else {
      setInputValue(newInputValue)
    }
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      autoSelect
      size='small'
      options={[]}
      value={value}
      inputValue={inputValue}
      limitTags={8}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option}
            {...getTagProps({ index })}
            variant='filled'
            sx={{
              textTransform: 'lowercase',
              height: '24px',
              '& .MuiChip-label': {
                paddingY: 0,
              },
            }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          multiline
          rows={1}
          label={label}
          variant='outlined'
          placeholder={isFocused && !value.length ? 'Type and press Enter to add...' : ''}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          InputLabelProps={{
            shrink: isFocused || inputValue.length > 0 || value.length > 0,
          }}
        />
      )}
      sx={styles.autocomplete}
    />
  )
}
