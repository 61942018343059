import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import React from 'react'
import { useGate } from 'statsig-react'

import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { QAWacWithoutResetFix } from './components/QaWacWithoutResetFix'
import { QAWacWithResetFix } from './components/QaWacWithResetFix'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TStep = TStepRun['step']

type IProps = WizardSchemas.WACConfig.TSchema & {
  stepRun: TStepRun & {
    step: TStep & {
      stepVariableReferences?: { baseVariableId: string; stepTemplateVariable: { key: string } }[]
    }
  }
  baseRun: TBaseRun
  isReadOnly: boolean
}

const QaWAC = (props: IProps) => {
  const { value: enableQAWacFormResetFix } = useGate('enable-qa-wac-form-reset-fix')
  return enableQAWacFormResetFix ? (
    <QAWacWithResetFix {...props} />
  ) : (
    <QAWacWithoutResetFix {...props} />
  )
}

export { QaWAC }
