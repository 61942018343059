import { Button } from '@invisible/ui/button'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { SingleDatePicker } from '@invisible/ui/single-date-picker'
import Stack from '@mui/material/Stack'
import MuiSwitch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { startCase } from 'lodash/fp'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { getAbstractTypeFileType } from '../helpers'
import { TParsedProperty } from '../types'
import { EnumField } from './EnumField'
import { StringArrayField } from './StringArrayField'
import { TextField as BaseVariableTextField } from './TextField'

interface IProps {
  formValues: Record<string, unknown>
  setFormValues: Dispatch<SetStateAction<Record<string, unknown>>>
  csvUploadInputRefs?: { current: HTMLInputElement[] }
  csvUploadInputRefMap: Record<string, number>
  handleClick: (e: React.MouseEvent<HTMLButtonElement>, label: string) => void
  handleCsvUpload: (label: string) => (e: ChangeEvent<HTMLInputElement>) => void
  field: TParsedProperty
}

const FieldComponent = ({
  formValues,
  setFormValues,
  csvUploadInputRefs,
  csvUploadInputRefMap,
  handleClick,
  handleCsvUpload,
  field,
}: IProps) => {
  if (field.fieldType === 'string' && field.format === 'date-time')
    return (
      <DateTimePicker
        views={['year', 'month']}
        value={formValues[field.label] as Date}
        hideTime={true}
        onChange={(value: Date | null) =>
          setFormValues((prev) => ({
            ...prev,
            [field.label]: value?.toISOString(),
          }))
        }
      />
    )

  if (field.fieldType === 'string')
    return (
      <BaseVariableTextField
        name={field.label}
        label={startCase(field.label)}
        type='text'
        value={formValues[field.label] as string}
        onChange={(name: string, value: string) => {
          setFormValues((prev) => ({
            ...prev,
            [name]: value,
          }))
        }}
      />
    )

  if (field.fieldType === 'number') {
    return (
      <TextField
        name={field.label}
        fullWidth
        size='small'
        label={startCase(field.label)}
        type='number'
        value={formValues[field.label]}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFormValues((prev) => ({
            ...prev,
            [field.label]: e.target.value,
          }))
        }}
      />
    )
  }

  if (field.fieldType === 'date') {
    return (
      <SingleDatePicker
        wFull
        onChange={(date) =>
          setFormValues((prev) => ({
            ...prev,
            [field.label]: date,
          }))
        }
      />
    )
  }

  if (field.fieldType === 'url') {
    return (
      <BaseVariableTextField
        name={field.label}
        label={startCase(field.label)}
        type='url'
        value={formValues[field.label] as string}
        onChange={(name: string, value: string) => {
          setFormValues((prev) => ({
            ...prev,
            [name]: value,
          }))
        }}
      />
    )
  }

  if (field.fieldType === 'email') {
    return (
      <BaseVariableTextField
        name={field.label}
        label={startCase(field.label)}
        type='email'
        value={formValues[field.label] as string}
        onChange={(name: string, value: string) => {
          setFormValues((prev) => ({
            ...prev,
            [name]: value,
          }))
        }}
      />
    )
  }

  if (field.fieldType === 'dropdown') {
    return (
      <EnumField
        value={(formValues[field.label] ?? '') as string}
        label={field.label}
        onChange={(value) => {
          setFormValues((prev) => ({
            ...prev,
            [field.label]: value,
          }))
        }}
        options={field.enum ?? []}
      />
    )
  }

  if (field.fieldType === 'boolean') {
    return (
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography>{startCase(field.label)}</Typography>
        <MuiSwitch
          checked={formValues[field.label] as boolean}
          size='medium'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFormValues((prev) => ({
              ...prev,
              [field.label]: e.target.checked,
            }))
          }}
        />
      </Stack>
    )
  }

  if (field.fieldType === 'array') {
    if (field.items?.type === 'object' && field.abstractType) {
      const fileType = getAbstractTypeFileType(field.abstractType)
      return (
        <>
          <Button variant='primary' size='md' onClick={(e) => handleClick(e, field.label)}>
            {formValues[field.label] ? 'Uploaded' : `Upload ${fileType.toUpperCase()}`}
          </Button>
          <input
            ref={(el) => {
              if (csvUploadInputRefs && el) {
                csvUploadInputRefs.current[csvUploadInputRefMap[field.label]] = el
              }
            }}
            type='file'
            accept={`.${fileType}`}
            onChange={handleCsvUpload(field.label)}
            hidden
          />
        </>
      )
    }
    if (field.items?.type === 'string') {
      return (
        <StringArrayField
          label={startCase(field.label)}
          value={(formValues[field.label] || []) as string[]}
          onChange={(newValues) => setFormValues((prev) => ({ ...prev, [field.label]: newValues }))}
        />
      )
    }

    return null
  }

  return null
}

export { FieldComponent }
