import MuiTextField from '@mui/material/TextField'
import { useState } from 'react'

interface TextFieldProps {
  name: string
  label: string
  type?: 'text' | 'email' | 'url'
  required?: boolean
  value: string
  onChange: (name: string, value: string) => void
}

const validateInput = (type: string, value: string, required: boolean) => {
  if (!value && !required) return ''

  switch (type) {
    case 'email':
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address'
    case 'url': {
      const urlPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z0-9]{1,}(\/.*)?$/i
      return urlPattern.test(value) ? '' : 'Invalid URL'
    }
    default:
      return required && !value ? `Field is required` : ''
  }
}

export const TextField = ({
  name,
  label,
  type = 'text',
  required = false,
  value,
  onChange,
}: TextFieldProps) => {
  const [error, setError] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    onChange(name, newValue)
    setError(validateInput(type, newValue, required))
  }

  return (
    <MuiTextField
      name={name}
      label={label}
      type={type}
      fullWidth
      size='small'
      value={value}
      onChange={handleChange}
      error={!!error}
      helperText={error}
      required={required}
    />
  )
}
