import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import MuiButton from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export interface IDataSetFieldProps {
  value: {
    file_name: string
    comapany_file_id: string
  }
  label: string
  fileType: string
  handleAddFileClick: (label: string, fileType: string) => void
  handleFileRemoval: (label: string) => void
}

const DatasetField = ({
  value,
  label,
  fileType,
  handleAddFileClick,
  handleFileRemoval,
}: IDataSetFieldProps) => {
  if (value)
    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        bgcolor='grey.200'
        borderRadius='4px'
        p='8px'>
        <Typography>{value.file_name}</Typography>
        <MuiButton sx={{ fontWeight: 'normal' }} onClick={() => handleFileRemoval(label)}>
          Remove
        </MuiButton>
      </Stack>
    )

  return (
    <MuiButton
      onClick={() => handleAddFileClick(label, fileType)}
      sx={{ fontWeight: 'normal' }}
      variant='outlined'
      component='label'
      startIcon={<FileUploadOutlinedIcon />}>
      Add {fileType.toUpperCase()}
    </MuiButton>
  )
}

export { DatasetField }
