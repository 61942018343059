import { useContext, useMutation } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { TextArea } from '@invisible/ui/form'
import { InfoFilledIcon, PlusIcon, TrashOutlineIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Modal } from '@invisible/ui/modal'
import { Switch } from '@invisible/ui/switch'
import { TagInput } from '@invisible/ui/tag-input'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import dynamic from 'next/dynamic'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import Markdown from 'react-markdown'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'

const JsonEditor = dynamic(() => import('react-json-view'), { ssr: false })

// The keys indicate the index of the message type selected in the WAC config
// E.g [user_prompt,bot_response,user1,user2,user3,...]
const colors: Record<number, string> = {
  0: 'bg-blue-50',
  1: 'bg-pink-50',
  2: 'bg-gray-50',
  3: 'bg-white',
  4: 'bg-green-50',
}

type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps {
  index: number
  messageId: string
  type: string
  text: string
  baseRunVariables: TBaseRunVariables
  config: WizardSchemas.SFT2.TSchema
  conversationId: string
  mostRecentMessageId: string
  metadataValidationFailures: string[]
  stepRunId: string
  wizardIsReadOnly: boolean
  onInsertAfter: () => void
  shiftIndicesBackward: (index: number) => Promise<void>
}

const SingleTurn = ({
  index,
  messageId,
  type,
  text,
  baseRunVariables,
  config,
  conversationId,
  mostRecentMessageId,
  metadataValidationFailures,
  stepRunId,
  wizardIsReadOnly,
  onInsertAfter,
  shiftIndicesBackward,
}: IProps) => {
  const { value: enableTextBoxToggling } = useGate('enable-text-to-code-toggling')

  const [isEditing, setIsEditing] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [messageText, setMessageText] = useState(text)

  const messageRef = useRef<HTMLElement | null>(null)

  const [metadataFormData, setMetadataFormData] = useState<Record<string, unknown>>({})

  const reactQueryContext = useContext()

  // We remove "address" from the list of allowed HTML Tags so it can be escaped and rendered as is
  const defaultAllowedTags = sanitize.defaults.allowedTags
  const defaultTagindex = defaultAllowedTags.indexOf('address')

  if (defaultTagindex !== -1) {
    defaultAllowedTags.splice(defaultTagindex, 1)
  }

  const { mutateAsync: updateVariable } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
    },
    onSuccess: (data) => {
      // Below we optimistically update the cache to reflect the new value of the variable
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: config.messagesBaseId,
            parentBaseRunId: conversationId,
          },
        ],
        (prevData) => {
          if (!prevData || !data?.length) return prevData // Ensure data[0] exists

          return prevData.map((baseRun) =>
            baseRun.id === data[0].baseRunId
              ? {
                  ...baseRun,
                  baseRunVariables: baseRun.baseRunVariables.map((v) =>
                    v.baseVariable.id !== data[0].baseVariableId
                      ? v
                      : { ...v, value: data[0].value }
                  ),
                }
              : baseRun
          )
        }
      )
    },
  })

  const { mutateAsync: updateManyVariables, isLoading: isUpdatingManyVariables } =
    useBaseRunVariablesWizardUpdate({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
    })

  const { mutateAsync: deleteBaseRuns, isLoading: isDeleting } =
    useBaseRunDeleteWithStepRunReference({
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
      },
      onSuccess: () => {
        // Below we optimistically update the cache to remove the BaseRun that we just deleted
        reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
          [
            'baseRun.findChildBaseRuns',
            {
              baseId: config.messagesBaseId,
              parentBaseRunId: conversationId,
            },
          ],
          (prevData) => {
            if (!prevData) return
            return prevData.filter((baseRun) => baseRun.id !== messageId)
          }
        )
      },
    })

  useEffect(() => {
    if (isEditing) messageRef?.current?.focus()
  }, [messageRef])

  // We set the initial state of the metadata form to the values of the variables that already exist in database
  useEffect(() => {
    // If the state is already set, then we don't reset it.
    if (Object.keys(metadataFormData).length > 0) return

    const initialMetadataFormValues: Record<string, unknown> = {}

    baseRunVariables.forEach((variable) => {
      initialMetadataFormValues[variable.baseVariableId as string] = variable.value
    })

    setMetadataFormData(initialMetadataFormValues)
  }, [baseRunVariables])

  // We update the message text when the text is edited and then focus moves away from the message
  const handleBlur = useCallback(
    async (value: string) => {
      setIsEditing(false)
      updateVariable({
        stepRunId,
        data: [
          {
            baseRunId: messageId,
            baseVariableId: config.textBaseVariableId as string,
            value,
          },
        ],
      })
    },
    [messageId, updateVariable]
  )

  const handleDelete = async () => {
    if (isDeleteModalOpen) {
      await deleteBaseRuns({ baseRunIds: [messageId], stepRunId: stepRunId })
      await shiftIndicesBackward(index)
    }
    setIsDeleteModalOpen(false)
  }

  const updateMetadata = async () => {
    // We create an array of objects that we can pass to the updateManyVariables mutation
    const updateData = Object.keys(metadataFormData).map((key) => ({
      baseRunId: messageId,
      baseVariableId: key,
      value: metadataFormData[key] as string,
    }))

    await updateManyVariables({ stepRunId, data: updateData })
    setIsDetailsOpen(false)
  }

  const getMessageColor = () => {
    // If the message is a validation failure, we color it red
    if (metadataValidationFailures.includes(messageId)) return 'bg-red-600'

    const index = config.messageTypes.indexOf(type)
    const color = index !== -1 ? colors[index] : ''

    if (color) {
      return color
    }

    // The first half of the message types are shades of purple
    if (config.messageTypes.indexOf(type) < config.messageTypes.length / 2) {
      return `bg-purple-${100 * (config.messageTypes.indexOf(type) + 1)}`
    }

    // The second half of the message types are shades of gray
    return `bg-gray-${
      100 * (config.messageTypes.indexOf(type) - Math.floor(config.messageTypes.length / 2) + 1)
    }`
  }
  const toggleEditing = () => {
    if (!config.readOnly && !wizardIsReadOnly) {
      setIsEditing(true)
    }
  }

  const isJSONString = (text: string) => {
    try {
      JSON.parse(text)
    } catch (e) {
      return false
    }
    return true
  }

  const htmlToPlainText = (htmlBody: string) => {
    // Create a temporary DOM element
    const tempElement = document.createElement('div')
    // Set the HTML content to the temporary element
    tempElement.innerHTML = htmlBody
    // Return the text content of the temporary element
    return tempElement.textContent || tempElement.innerText
  }

  const addCodeMarkdown = (formatText: string | boolean, eventType: string, textString: string) => {
    if (enableTextBoxToggling && formatText) {
      if (eventType === 'onChange') {
        textString = htmlToPlainText(textString)
      }
      // Re-add Markdown code block syntax after editing
      textString = '```\n' + textString + '\n```'
    }
    return textString
  }

  const getMessageDisplayBox = () => {
    if (isEditing && !config.readOnly && !wizardIsReadOnly) {
      // Check if messageText contains Markdown code block syntax
      const containsMarkdownCodeBlock = messageText && /```[\s\S]*\n/.test(messageText)
      let displayText = messageText

      if (enableTextBoxToggling && containsMarkdownCodeBlock) {
        // Strip Markdown code block syntax when editing
        displayText = messageText.replace(/```\n/, '').replace(/\n```/, '')
      }

      return (
        <ContentEditable
          className='whitespace-pre-line break-words rounded-xl px-4 py-2 text-sm'
          html={sanitize(displayText ?? '', { disallowedTagsMode: 'escape' })}
          innerRef={messageRef}
          disabled={!isEditing}
          onChange={(e) => {
            let updatedText = e.target.value
            if (enableTextBoxToggling) {
              updatedText = addCodeMarkdown(containsMarkdownCodeBlock, 'onChange', updatedText)
            }
            setMessageText(updatedText)
          }}
          onBlur={(e) => {
            let updatedText = e.target.innerText
            if (enableTextBoxToggling) {
              updatedText = addCodeMarkdown(containsMarkdownCodeBlock, 'onBlur', updatedText)
            }
            handleBlur(updatedText)
          }}
        />
      )
    }

    if (isJSONString(messageText)) {
      return <JsonEditor src={JSON.parse(messageText)} name={false} collapsed={2} />
    }

    return <Markdown className='overflow-auto'>{messageText}</Markdown>
  }

  return (
    <div className='mb-3'>
      <div className='group mb-1 flex items-center gap-1'>
        <div
          className={`w-full rounded-xl shadow ${getMessageColor()}`}
          onDoubleClick={() => toggleEditing()}>
          {!isEditing ? (
            <div className='right-0 top-0 flex w-full select-none flex-row justify-between rounded-tl-xl rounded-tr-xl bg-gray-800 text-[8px] text-white'>
              <span className='p-1'>Role: {type}</span>
              <span className='p-1'>{index}</span>
            </div>
          ) : null}
          <div className='py-2 px-4'>{getMessageDisplayBox()}</div>
        </div>
        <div className='flex w-16 flex-row'>
          <InfoFilledIcon
            className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
            onClick={() => setIsDetailsOpen(true)}
          />
          {mostRecentMessageId !== messageId &&
          config.allowInserts &&
          !config.readOnly &&
          !wizardIsReadOnly ? (
            <PlusIcon
              className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
              onClick={() => onInsertAfter()}
            />
          ) : null}
          {mostRecentMessageId === messageId ||
          (config.allowDeletes && !config.readOnly && !wizardIsReadOnly) ? (
            <TrashOutlineIcon
              className='invisible h-4 w-4 cursor-pointer pr-1 text-gray-500 group-hover:visible'
              onClick={() => setIsDeleteModalOpen(true)}
            />
          ) : null}
        </div>
      </div>

      <Modal
        visible={isDetailsOpen}
        title='Add Metadata'
        onClose={() => setIsDetailsOpen(false)}
        primaryButton={
          <Button
            variant='primary'
            size='md'
            onClick={async () => {
              await updateMetadata()
            }}
            loading={isUpdatingManyVariables}>
            Update Metadata
          </Button>
        }>
        <div className='flex w-96 flex-col gap-4'>
          {(config.metadata ?? []).map((item) => {
            const value = metadataFormData ? metadataFormData[item.baseVariableId] : ''
            return (
              <div>
                <div className='mb-1'>{item.name}</div>
                {item.type === 'a_string' ? (
                  <TagInput
                    key={item.baseVariableId}
                    tags={(value ?? []) as string[]}
                    onChange={(value) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: value,
                      }))
                    }
                  />
                ) : item.type === 'boolean' ? (
                  <Switch
                    size='medium'
                    isOn={(value ?? false) as boolean}
                    onToggle={(value) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: value,
                      }))
                    }
                  />
                ) : item.type === 'string' ? (
                  <Input
                    key={item.baseVariableId}
                    defaultValue={(value ?? '') as string}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: e.target.value,
                      }))
                    }
                  />
                ) : (
                  <TextArea
                    width='100%'
                    key={item.baseVariableId}
                    value={(value ?? '') as string}
                    onChange={(e) =>
                      setMetadataFormData((prev) => ({
                        ...prev,
                        [item.baseVariableId]: e.target.value,
                      }))
                    }
                  />
                )}
              </div>
            )
          })}
        </div>
      </Modal>
      <Modal
        visible={isDeleteModalOpen}
        title={'Delete Last Message'}
        onClose={() => setIsDeleteModalOpen(false)}
        primaryButton={
          <Button variant='danger' loading={isDeleting} onClick={() => handleDelete()}>
            Yes
          </Button>
        }
        secondaryButton={
          <Button variant='secondary' onClick={() => setIsDeleteModalOpen(false)}>
            No
          </Button>
        }>
        Are you sure you want to delete this last message?
      </Modal>
    </div>
  )
}

export { SingleTurn }
