import { z } from '@invisible/zod'

const schema = z.object({
  languageBaseRunVariableId: z.string().optional(),
  versionBaseRunVariableId: z.string().optional(),
  environmentBaseRunVariableId: z.string().optional(),
  codeBaseRunVariableId: z.string().optional(),
  imageBaseRunVariableId: z.string().optional(),
  reasonBaseRunVariableId: z.string().optional(),
  successBaseRunVariableId: z.string().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
