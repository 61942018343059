import { UuidSchema, z } from '@invisible/zod'

const baseVariableMappingAbstractTypeSchema = z.enum(['csv_file', 'json_file', 'jsonl_file'])

/**
 * A pair of baseVariableId and key. The key matches up with the trigger's payload.
 */
const schema = z
  .object({
    baseVariableId: UuidSchema,
    key: z.string(),
    abstractType: baseVariableMappingAbstractTypeSchema.optional(),
  })
  .strict()
/**
 * A pair of baseVariableId and key. The key matches up with the trigger's payload.
 */
type TSchema = z.infer<typeof schema>
type TBaseVariableMappingAbstractType = z.infer<typeof baseVariableMappingAbstractTypeSchema>

export { schema }
export type { TBaseVariableMappingAbstractType, TSchema }
